import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/sources',
  },
  {
    path: '/sources',
    name: 'sources',
    component: () => import(/* webpackChunkName: "sources" */ '../pages/sources.vue'),
  },
  {
    path: '/feed',
    name: 'feed',
    component: () => import(/* webpackChunkName: "feed" */ '../pages/feed.vue'),
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import(/* webpackChunkName: "articles" */ '../pages/articles.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL || '',
  routes,
});

export default router;
